import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const recipeTemplate = ({ data, location, pageContext }) => {
  const recipe = pageContext.recipe

  return (
    <Layout location={location}>
      <SEO title={recipe.frontmatter.title} description={recipe.excerpt} />
      <h1>{recipe.frontmatter.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: recipe.html }} />
    </Layout>
  )
}

export default recipeTemplate
